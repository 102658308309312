import React, { FC, useEffect, useState } from 'react';
import Button from "../../components/form/Button";
import ArrowLeft from "../../img/icon/ArrowLeft";
import { useLocation, useNavigate } from "react-router-dom";
import Info from "../../img/icon/Info";
import TextField from "../../components/form/TextField";
import { ToggleBlock } from "../../components/form/ToggleBlock";
import FilterSelect from "../../components/FilterSelect";
import DatePickerField from "../../components/form/DatePickerField";
import Calendar from "../../img/icon/Calendar";
import Person from "../../img/icon/Person";
import TransformObjArray from "../../mixins/transform-obj-array";
import { $createTournaments, $getTournamentsListById, $updateTournaments } from "../../api/requests/tournaments";
import { DashboardTournamentCreate } from "../../api/requests/tournaments/interface";
import DateOperations from "../../mixins/date-operation";
import ImageField from "../../components/form/ImageField";
import ImageToBase64 from "../../mixins/image-to-base64";
import { $getListDiscipline } from "../../api/requests/discipline";
import TextArea from "../../components/form/TextArea";

const TournamentPage: FC = () => {
    const isMobile = window.innerWidth <= 465;
    const location = useLocation();
    const navigate = useNavigate();

    const dateOperations = new DateOperations();
    const imageToBase64 = new ImageToBase64();
    const transformObjArray = new TransformObjArray();

    const state: any = location.state;
    const isEditPage = state?.current === 'edit';
    const editedData = state?.data || {};

    const [ image, setImage ] = useState<any>(null);

    const typeTournaments = [
        {
            value: 1,
            label: 'Командный'
        },
        {
            value: 2,
            label: 'Одиночный'
        },
    ]

    const typeDisciplines = [
        {
            key: 'general',
            value: 1,
            label: 'Общая'
        },
        {
            key: 'priority',
            value: 2,
            label: 'Приоритетная'
        }
    ]

    const defaultTournamentsItem = [
        {
            key: 'title',
            placeholder: 'Название раунда',
            imgRight: '',//<Info color={ '#3582F6' }/>,
            value: '',
            field: true,
            svg: <Person color={ '#3582F6' }/>,
            setValue: (value: string, key: string, list: any) => onChangeRoundSetValue(value, key, list),
            left: true,
            className: 'tournament__edit-content-multi'
        },
        {
            key: 'date',
            placeholder: 'Дата раунда',
            image: <Calendar color={ '#3582F6' }/>,
            value: null,
            date: true,
            svg: <Person color={ '#3582F6' }/>,
            setValue: (value: string, key: string, list: any) => onChangeRoundSetValue(value, key, list),
            className: 'tournament__edit-content-multi'
        }
    ];

    const [ tournamentList, setTournamentList ] = useState<any[]>([
        {
            key: 'title',
            label: 'Название',
            value: '',
            field: true,
        },
        {
            key: 'description',
            placeholder: 'Описание',
            value: '',
            textarea: true,
            className: 'form-group-area'
        },
        {
            key: 'type',
            title: 'Тип турнира',
            value: '',
            imgRight: '',//<Info color={ '#3582F6' }/>,
            placeholder: 'Выберите тип турнира',
            select: true,
            options: typeTournaments
        },
        {
            key: 'isRanking',
            value: false,
            toggleText: 'Рейтинговый',
            checkbox: true,
            className: 'w-100 mt-2'
        },
        {
            key: 'disciplineId',
            title: 'Дисциплина',
            imgRight: '',//<Info color={ '#3582F6' }/>,
            value: '',
            placeholder: 'Выбрать',
            select: true,
            options: []
        },
        {
            key: 'ratingMax',
            title: 'Ограничения по очкам',
            imgRight: '',//<Info color={ '#3582F6' }/>,
            value: '',
            placeholder: 'Max.',
            field: true,
            left: true,
            className: 'tournament__edit-content-multi'
        },
        {
            key: 'ratingMin',
            imgRight: '',//<Info color={ '#3582F6' }/>,
            value: '',
            placeholder: 'Min.',
            field: true,
            className: 'tournament__edit-content-multi'
        },
        {
            key: 'dateApplicationsStart',
            title: 'Период принятия заявок',
            value: null,
            placeholder: 'Старт',
            imgRight: '',//<Info color={ '#3582F6' }/>,
            image: <Calendar color={ '#3582F6' }/>,
            date: true,
            left: true,
            className: 'tournament__edit-content-multi'
        },
        {
            key: 'dateApplicationsEnd',
            value: null,
            placeholder: 'Конец',
            imgRight: '',//<Info color={ '#3582F6' }/>,
            image: <Calendar color={ '#3582F6' }/>,
            date: true,
            className: 'tournament__edit-content-multi'
        },
        {
            key: 'dateStart',
            title: 'Дата начала',
            placeholder: 'Старт',
            imgRight: '',//<Info color={ '#3582F6' }/>,
            image: <Calendar color={ '#3582F6' }/>,
            value: null,
            date: true,
            left: true,
            className: 'tournament__edit-content-multi'
        },
        {
            key: 'dateEnd',
            placeholder: 'Конец',
            imgRight: '',//<Info color={ '#3582F6' }/>,
            image: <Calendar color={ '#3582F6' }/>,
            value: null,
            date: true,
            className: 'tournament__edit-content-multi'
        },
        {
            key: 'count',
            title: 'Количество команд',
            placeholder: 'Выберите',
            imgRight: '',//<Info color={ '#3582F6' }/>,
            value: null,
            select: true,
            options: [
                {
                    label: '2',
                    value: 2
                },
                {
                    label: '4',
                    value: 4
                },
                {
                    label: '8',
                    value: 8
                },
                {
                    label: '16',
                    value: 16
                },
                {
                    label: '32',
                    value: 32
                },
                {
                    label: '64',
                    value: 64
                },
                // {
                //     label: '128',
                //     value: 128
                // },
                // {
                //     label: '256',
                //     value: 256
                // },
                // {
                //     label: '512',
                //     value: 512
                // },
            ],
            svg: <Person color={ '#3582F6' }/>,
            className: 'mb-0'
        },
        {
            key: 'countMembers',
            title: 'Количество участников в команде',
            placeholder: 'Введите',
            imgRight: '',//<Info color={ '#3582F6' }/>,
            value: '',
            type: 'number',
            field: true,
            svg: <Person color={ '#3582F6' }/>,
            className: 'mb-0',
            disabled: false,
            hide: false
        },
        {
            key: 'firstPlace',
            title: 'Начисляемые очки за турнир',
            label: '1 место',
            value: 100,
            type: 'number',
            field: true,
            left: true,
            className: 'tournament__edit-content-multi',
            hide: true
        },
        {
            key: 'secondPlace',
            label: '2 место',
            value: 75,
            type: 'number',
            field: true,
            className: 'tournament__edit-content-multi',
            hide: true
        },
        {
            key: 'thirdPlace',
            label: '3 место',
            value: 50,
            type: 'number',
            field: true,
            left: true,
            className: 'tournament__edit-content-multi',
            hide: true
        },
        {
            key: 'placeMembers',
            label: 'Непризовое участие',
            value: 25,
            type: 'number',
            field: true,
            className: 'tournament__edit-content-multi',
            hide: true
        },
        {
            key: 'firstPoints',
            title: 'Начисляемые токены за турнир',
            label: '1 место',
            value: 0,
            type: 'number',
            field: true,
            left: true,
            className: 'tournament__edit-content-multi',
            hide: true
        },
        {
            key: 'secondPoints',
            label: '2 место',
            value: 0,
            type: 'number',
            field: true,
            className: 'tournament__edit-content-multi',
            hide: true
        },
        {
            key: 'thirdPoints',
            label: '3 место',
            value: 0,
            type: 'number',
            field: true,
            left: true,
            className: 'tournament__edit-content-multi',
            hide: true
        },
        {
            key: 'pointsMembers',
            label: 'Непризовое участие',
            value: 0,
            type: 'number',
            field: true,
            className: 'tournament__edit-content-multi',
            hide: true
        }
    ]);

    const [ tournamentRoundsList, setTournamentRoundsList ] = useState<any[]>([
        {
            title: 'Раунды'
        },
        ...defaultTournamentsItem
    ]);

    const [ disciplineList, setDisciplineList ] = useState<any[]>([]);

    const updateTournamentRoundsList = (count: number, listEdit?: any[]) => {
        const calcCount = calculateRounds(count)

        const updatedRoundsList = [
            {
                title: 'Раунды'
            },
            ...defaultTournamentsItem.map(i => {
                if (listEdit) return {
                    ...i,
                    value: listEdit[0][i.key]
                }

                return i
            })
        ];

        for (let i = 0; i < calcCount; i++) {
            updatedRoundsList.push(...defaultTournamentsItem.map((item) => {
                if (listEdit) {
                    const findItem = listEdit.find(_i => _i.round === (i + 1));

                    return {
                        ...item,
                        key: item.key + updatedRoundsList.length,
                        value: findItem[item.key]
                    }
                }

                return {
                    ...item,
                    key: item.key + updatedRoundsList.length
                }
            }));
        }

        while (calcCount > updatedRoundsList.length) {
            updatedRoundsList.pop();
        }

        setTournamentRoundsList(updatedRoundsList);
    };

    const getRoundList = (): any[] => {
        const list = tournamentRoundsList.filter(item => item.key)

        const combinedItems = [];

        for (let i = 0; i < list.length; i += 2) {
            const item1 = list[i];
            const item2 = list[i + 1];

            const combinedItem = {
                title: item1.value,
                date: item2.value ? getCurrentDateText(item2.value) : '',
            };

            combinedItems.push(combinedItem);
        }

        return combinedItems
    }

    const getCurrentDateText = (date: string | Date): string =>  {
        if (!date) return '';

        if (date instanceof Date) return dateOperations.getISODateTimeByObj(date)

        return date
    }

    useEffect(() => init(), []);

    function init() {
        $getListDiscipline().then(res => {
            if (!res.length) return;

            const list = res.map((i) => ({
                ...i,
                label: i.name,
                value: i.id,
            }))

            setDisciplineList(list)

            const listUpdate = [ ...tournamentList ].map(i => {
                if (editedData && Object.keys(editedData).includes(i.key)) i.value = editedData[i.key] || '';

                if (i.options) {
                    const findItem: any = i.options.find((_i: any) => _i.value === editedData[i.key])

                    i.value = findItem || '';
                }

                if (i.key === "disciplineId") {
                    const findIsRanking = tournamentList.find((item: any) => (item.key === "isRanking") && !!item.value)

                    i.options = findIsRanking && list.filter((item) => item.isRating
						? true
						: (item.isRating === (editedData.isRanking ? editedData.isRanking : findIsRanking.value))) || list
                    i.value = list.find((_i: any) => _i.id === editedData[i.key])
                }

                if (i.key === 'type') {
                    const findItem = typeTournaments.find(item => item.value === i.value?.value);

                    findItem && onChangeTypeSetValue(findItem, 'type')

                    i.value = findItem ? findItem : editedData[i.key]
                }

                if ([
                    'firstPlace', 'secondPlace', 'thirdPlace',
                    'placeMembers', 'firstPoints', 'secondPoints',
                    'thirdPoints', 'pointsMembers'
                ].includes(i.key)) {
                    i.hide = !editedData.isRanking
                }

                return i;
            });

            updateTournamentRoundsList(editedData.count);
            setTournamentList(listUpdate);
        })

        const listUpdate = [ ...tournamentList ].map(i => {
            if (editedData && Object.keys(editedData).includes(i.key)) i.value = editedData[i.key] || '';

            if (i.options) {
                const findItem: any = i.options.find((_i: any) => _i.value === editedData[i.key])

                i.value = findItem || '';
            }

            if (i.key === 'type') {
                const findItem = typeTournaments.find(item => item.value === i.value);

                i.value = findItem && findItem || editedData[i.key]
            }

            return i;
        });

        if (editedData.image) setImage(editedData.image)

        setTournamentList(listUpdate);
        updateTournamentRoundsList(editedData.count);

        if (editedData.id) getInitRoundList()
    }

    function getInitRoundList() {
        $getTournamentsListById(editedData.id).then((res: any) => {
            if (!res.id) return;

            updateTournamentRoundsList(editedData.count, res.rounds);

            const listUpdate = [ ...tournamentList ].map(i => {
                if (res.prizes) {
                    if (i.key === 'firstPlace') {
                        const findItem = res.prizes.find((i: any) => i.place === 1)

                        i.value = findItem.rating
                    }

                    if (i.key === 'secondPlace') {
                        const findItem = res.prizes.find((i: any) => i.place === 2)

                        i.value = findItem.rating
                    }

                    if (i.key === 'thirdPlace') {
                        const findItem = res.prizes.find((i: any) => i.place === 3)

                        i.value = findItem.rating
                    }

                    if (i.key === 'placeMembers') {
                        const findItem = res.prizes.find((i: any) => i.place === 0)

                        i.value = findItem.rating
                    }

                    if (i.key === 'firstPoints') {
                        const findItem = res.prizes.find((i: any) => i.place === 1)

                        i.value = findItem.points
                    }

                    if (i.key === 'secondPoints') {
                        const findItem = res.prizes.find((i: any) => i.place === 2)

                        i.value = findItem.points
                    }

                    if (i.key === 'thirdPoints') {
                        const findItem = res.prizes.find((i: any) => i.place === 3)

                        i.value = findItem.points
                    }

                    if (i.key === 'pointsMembers') {
                        const findItem = res.prizes.find((i: any) => i.place === 0)

                        i.value = findItem.points
                    }
                }

                return i;
            });

            setTournamentList(listUpdate);
        })
    }

    function onChangeSetValue(value: any, key: string) {
        const listUpdates: any = [ ...tournamentList ].map(i => {
            if (i.key === key) i.value = value;

            return i;
        });

        if (key === 'count') {
            updateTournamentRoundsList(value.value);
        }

        setTournamentList(listUpdates);
    }

    function onChangeTypeSetValue(value: any, key: string) {
        let listUpdates: any = [ ...tournamentList ]

        if (key === 'isRanking') {
            listUpdates = listUpdates.map((i: any) => {
                if (i.key === "disciplineId") {
                    i.options = [...disciplineList].filter((item) => item.isRating ? true : (item.isRating === value))
                    i.value = null
                }

                return i
            })
        }

        listUpdates = listUpdates.map((i: any) => {
            if (i.key === key) i.value = value;

            if (key === 'type') {
                if (i.key === 'count') {
                    i.title = value.value === 2 ? 'Количество участников' : 'Количество команд';
                }

                if (i.key === 'countMembers') {
                    i.value = value.value === 2 ? 1 : '';
                    i.disabled = i.hide = value.value === 2;
                }
            }

            if ((key === 'isRanking') && [
                'firstPlace', 'secondPlace', 'thirdPlace',
                'placeMembers', 'firstPoints', 'secondPoints',
                'thirdPoints', 'pointsMembers'
            ].includes(i.key)) {
                i.hide = !value
            }

            return i;
        });

        setTournamentList(listUpdates);
    }

    function onChangeRoundSetValue(value: string, key: string, list: any[]) {
        const listUpdates: any = [ ...list ].map(i => {
            if (i.key === key) i.value = value;

            return i;
        });

        setTournamentRoundsList(listUpdates);
    }

    function calculateRounds(teams: number): number {
        if (teams <= 1) {
            return 0; // Нет раундов, так как нет команд или только одна команда.
        }

        let rounds = 0;
        let remainingTeams = teams;

        while (remainingTeams > 1) {
            remainingTeams = Math.ceil(remainingTeams / 2);
            rounds++;
        }

        return rounds;
    }

    function onClickBack() {
        navigate('/tournaments/list');
    }

    function onClickSave() {
        const objKey = transformObjArray.getRequestObj(tournamentList);

        const requestData: DashboardTournamentCreate = {
            ...objKey,
            ratingMin: +objKey.ratingMin,
            ratingMax: +objKey.ratingMax,
            count: +objKey.count,
            countMembers: +objKey.countMembers,
            dateStart: getCurrentDateText(objKey.dateStart),
            dateEnd: getCurrentDateText(objKey.dateEnd),
            dateApplicationsStart: getCurrentDateText(objKey.dateApplicationsStart),
            dateApplicationsEnd: getCurrentDateText(objKey.dateApplicationsEnd),
            rounds: getRoundList(),
            image: imageToBase64.getImageCheckValid(image),
            status: isEditPage ? editedData.status : undefined,
            prizes: [
                {
                    place: 1,
                    points: +objKey.firstPoints,
                    rating: +objKey.firstPlace
                },
                {
                    place: 2,
                    points: +objKey.secondPoints,
                    rating: +objKey.secondPlace
                },
                {
                    place: 3,
                    points: +objKey.thirdPoints,
                    rating: +objKey.thirdPlace
                },
                {
                    place: 0,
                    points: +objKey.pointsMembers,
                    rating: +objKey.placeMembers
                }
            ],
            firstPoints: undefined,
            firstPlace: undefined,
            secondPoints: undefined,
            secondPlace: undefined,
            thirdPoints: undefined,
            thirdPlace: undefined,
            pointsMembers: undefined,
            placeMembers: undefined
        }

        if (isEditPage) return onEditSave(requestData);

        onCreateSave(requestData);
    }

    function getPriceList(commands: number, obj: any) {
        const list = [];

        for(let i=0; i<commands; i++) {
            if (i===0) {
                list.push({
                    place: 1,
                    points: +obj.firstPoints,
                    rating: +obj.firstPlace
                })
            } else if (i===1) {
                list.push({
                    place: 2,
                    points: +obj.secondPoints,
                    rating: +obj.secondPlace
                })
            } else if (i===2) {
                list.push({
                    place: 3,
                    points: +obj.thirdPoints,
                    rating: +obj.thirdPlace
                })
            } else list.push({
                place: i + 1,
                points: +obj.pointsMembers,
                rating: +obj.placeMembers
            })
        }

        return list
    }

    function onEditSave(data: DashboardTournamentCreate) {
        $updateTournaments(editedData.id, data).then(res => {
            if (!res.id) return;

            onClickBack();
        })
    }

    function onCreateSave(data: DashboardTournamentCreate) {
        $createTournaments(data).then(res => {
            if (!res.id) return;

            onClickBack();
        })
    }

    return (
        <div className="tournament tournament__edit">
            <div className="tournament__edit-main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="tournament__edit__back">
                                <Button
                                    text={!isMobile && 'Назад' }
                                    leftIcon={ (
                                        <ArrowLeft
                                            color={ '#3582F6' }
                                        />
                                    ) }
                                    onClick={ onClickBack }
                                    className={ 'btn btn-icon' }
                                />

                                {isMobile && (
                                    <h2>{ isEditPage ? 'Редактирование' : 'Создание' } турнира</h2>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="row column">
                        <div className="col-sm-7 col-md-8 col-xl-6 tournament__edit-style">
                            <form className="tournament__edit-content">
                                <div className="tournament__edit-content__cover">
                                    { isMobile ? (
                                            <h4 className={'tournament__edit__inner__mobile__text'}>Название турнира</h4>
                                        ) :
                                        (
                                            <h2>{ isEditPage ? 'Редактирование' : 'Создание' } турнира</h2>
                                        )
                                    }
                                </div>

                                { tournamentList.map((item, idx) => {
                                    if (item.hide) return null;

                                    return (
                                        <React.Fragment key={ `tournament-edit-item-${ idx }` }>
                                            { item.title && (
                                                <div
                                                    className='tournament__edit-content__text'
                                                    key={ `tournament-edit-title-${ idx }` }
                                                >
                                                    <p>{ item.title }</p>

                                                    { item.imgRight }
                                                </div>
                                            ) }

                                            { item.field && (
                                                <>
                                                    <TextField
                                                        type={ item.type }
                                                        className={ item.className }
                                                        placeholder={ item.placeholder }
                                                        value={ item.value }
                                                        label={ item.label }
                                                        imgLeft={ item.svg }
                                                        disabled={ item.disabled }
                                                        onChangeValue={ (value) => onChangeSetValue(value, item.key) }
                                                    />

                                                    { item.left && <div className="field__line"></div> }
                                                </>
                                            ) }

                                            { item.textarea && (
                                                <TextArea
                                                    placeholder={ item.placeholder }
                                                    value={ item.value }
                                                    onChangeValue={ (value) => onChangeSetValue(value, item.key) }
                                                    className={ item.className }
                                                    rows={2}
                                                />
                                            ) }

                                            { item.date && (
                                                <>
                                                    <DatePickerField
                                                        className={ item.className }
                                                        placeholder={ item.placeholder }
                                                        isIcon={ item.image }
                                                        startDate={ item.value }
                                                        onChangeDate={ (date) => onChangeSetValue(date, item.key) }
                                                    />

                                                    { item.left && <div className="field__line"></div> }
                                                </>
                                            ) }

                                            { item.select && (
                                                <FilterSelect
                                                    className={ item.className }
                                                    placeholder={ item.placeholder }
                                                    value={ item.value }
                                                    options={ item.options }
                                                    onChange={ (value) => (item.key === 'type') ? onChangeTypeSetValue(value, item.key) : onChangeSetValue(value, item.key) }
                                                />
                                            ) }

                                            { item.checkbox && (
                                                <ToggleBlock
                                                    desc={ item.toggleText }
                                                    isChecked={ item.value }
                                                    handleChange={ (value) => (item.key === 'isRanking') ? onChangeTypeSetValue(value, item.key) : onChangeSetValue(value, item.key) }
                                                    className={ item.className }
                                                />
                                            ) }
                                        </React.Fragment>
                                    )
                                }) }

                                { tournamentRoundsList.map((item, idx) => (
                                    <React.Fragment key={ `tournament-rounds-edit-item-${ idx }` }>
                                        { item.title && (
                                            <div
                                                className='tournament__edit-content__text'
                                                key={ `tournament-edit-title-${ idx }` }
                                            >
                                                <p>{ item.title }</p>

                                                { item.imgRight }
                                            </div>
                                        ) }

                                        { item.field && (
                                            <>
                                                <TextField
                                                    className={ item.className }
                                                    placeholder={ item.placeholder }
                                                    value={ item.value }
                                                    label={ item.label }
                                                    imgLeft={ item.svg }
                                                    onChangeValue={ (value) => item.setValue(value, item.key, tournamentRoundsList) }
                                                />

                                                { item.left && <div className="field__line"></div> }
                                            </>
                                        ) }

                                        { item.date && (
                                            <>
                                                <DatePickerField
                                                    className={ item.className }
                                                    placeholder={ item.placeholder }
                                                    isIcon={ item.image }
                                                    startDate={ item.value }
                                                    onChangeDate={ (date) => item.setValue(date, item.key, tournamentRoundsList) }
                                                />

                                                { item.left && <div className="field__line"></div> }
                                            </>
                                        ) }
                                    </React.Fragment>
                                )) }

                                <div className="tournament__edit-content__btn">
                                    <Button
                                        onClick={ onClickSave }
                                        className={ 'btn btn-primary tournament__edit-content__btn-button m-0' }
                                        text={ isEditPage ? 'Обновить' : 'Создать' }
                                    />
                                </div>
                            </form>
                        </div>

                        <div className="col-sm-5 col-md-4 col-xl-3 tournament__edit-page">
                            <div className="tournament__edit__inner">
                                <div className="tournament__edit__inner__right">
                                    { isMobile ? (
                                            <h4 className={'tournament__edit__inner__mobile__text'}>Обложка</h4>
                                        ) :
                                        (
                                            <h2>Обложка турнира</h2>
                                        )
                                    }
                                    {/*<Info*/}
                                    {/*    color={ '#3582F6' }*/}
                                    {/*/>*/}
                                </div>

                                <ImageField
                                    label={ '960x540' }
                                    upload={ image }
                                    setUpload={ setImage }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TournamentPage;
