import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store";

const initialState: any = {
    data: null,
	currentScroll: 0
};

const zoneSlice = createSlice({
    name: 'zone',
    initialState,
    reducers: {
        changeZoneList: (state, action: PayloadAction<any[]>) => {
            state.data = action.payload;
        },

		setScrollCalendar: (state, action: PayloadAction<number>) => {
            state.currentScroll = action.payload;
        },
    }
});
export const { changeZoneList, setScrollCalendar } = zoneSlice.actions;

export const getZoneData = (state: RootState) => state.zone.data;
export const getCurrentScrollData = (state: RootState) => state.zone.currentScroll;

export default zoneSlice.reducer;
