import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { DialogCalendarBooking } from "../../components/dialog/DialogCalendarBooking";

import CalendarBlock, { ListData } from "../../components/calendar";
import DialogContainer from "../../components/dialog/DialogContainer";
import DatePickerField from "../../components/form/DatePickerField";
import Button from "../../components/form/Button";

import { $getBooking } from "../../api/requests/booking";
import { $getCurrentZone } from "../../api/requests/zone";

import RoleUsers from "../../mixins/role";
import DateOperations from "../../mixins/date-operation";

import { GetCurrentUserData } from "../../redux/slices/userSlice";

const BookingPage: FC = () => {
    const dateOperations = new DateOperations();
    const roleUsers = new RoleUsers();

    const userData = useSelector(GetCurrentUserData);

    const isCreateByRole = roleUsers.getModelRoleByType('booking', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('booking', 'update');

    const [ isShowDialog, setIsShowDialog ] = useState<boolean>(false);
    const [ valueZone, setValueZone ] = useState<string>('');
    const [ currentDate, setCurrentDate ] = useState<Date>(new Date());

    const [ editBookingData, setEditBookingData ] = useState<any>(null);

    const [ filterProps, setFilterProps ] = useState<any>({
        paginated: true,
        page: 1
    });

    const [ zonesDropdownList, setZonesDropdownList ] = useState<any[]>([
        {
            label: 'Все зоны',
            value: 0
        }
    ]);

    const [ zonesList, setZonesList ] = useState<any[]>([]);

    const getDateBySeconds = (value: number, defaultValue: string) => {
        return value
            ? `${dateOperations.getTimeFromSeconds(value)}:00`
            : defaultValue
    }

    const dateWork = {
        start: getDateBySeconds(userData?.staffCenter?.start, '10:00:00'),
        end: getDateBySeconds(userData?.staffCenter?.end, '22:00:00')
    }

    const [ bookingList, setBookingList ] = useState<any[]>([])

    const secondsToTime = (seconds: number): string => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);

        const formattedHours = hours < 10 ? `0${ hours }` : `${ hours }`;
        const formattedMinutes = minutes < 10 ? `0${ minutes }` : `${ minutes }`;

        return `${ formattedHours }:${ formattedMinutes }`;
    };

    const getChangeBookingItemsList = (booking: any) => {
        return {
            ...booking,
            from: secondsToTime(booking.from_time),
            to: secondsToTime(booking.to_time),
            color: booking.adminId ? '#4A80EE' : '#7F67D3',
        }
    }

    useEffect(() => init(), [])

    function init() {
        $getCurrentZone().then((res) => {
            if (!res.result) return;

            const resUpdate = res.result.map((i: any) => {
                return {
                    ...i,
                    label: i.name,
                    value: i.id
                }
            })

            const list = [ ...zonesDropdownList ]

            list.push(...resUpdate)

            setZonesDropdownList(list)
            setZonesList(resUpdate)

            setCurrentDate(new Date());
            getBookingList(new Date(), resUpdate)
        })
    }

    function getBookingList(date: Date, zonePropsList?: any) {
        const dateFormat = dateOperations.getISODateByObj(date);

        $getBooking(dateFormat).then(response => {
            const booking = response || [];

            const list = [ ...booking ].map(i => {
                const deviceList: any[] = [];

                const newBookings = i.bookings.map(_i => getChangeBookingItemsList(_i))

                newBookings.forEach((item: any, idx) => {
                    if (item.devices) {
                        // const findItem = deviceList.findIndex(_i => _i.devices.some((__i: any) => __i.devices[0]?.deviceId === item.devices[0]?.deviceId));
						//
                        // if (findItem && findItem !== -1) {
                        //     deviceList[findItem].devices.push(item)
                        // } else {
                        //     deviceList.push({
                        //         devices: [item]
                        //     })
                        // }

						deviceList.push({
							devices: [item]
						})
                    }
                })

                return {
                    ...i,
                    items: deviceList
                }
            })

            setBookingList(list)
        })
    }

    function openDialog(is?: boolean, item?: ListData, isEdit?: boolean) {
        if (isEdit) {
            setEditBookingData(item)
        }

        setIsShowDialog(is || false)
    }

    const navigate = useNavigate();

    function onClickHome() {
        navigate("/");
    }

    function onFilterChange(value: any) {
        setValueZone(value)
    }

    return (
        <>
            <div className="booking list-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="booking__header">
                            <h1>Бронирование</h1>
                        </div>
                        <div className="booking__blocks-btn">
                            <div className={ 'booking__block' }>
                                <div className="booking__datepicker">
                                    <DatePickerField
                                        isIcon
                                        startDate={ currentDate }
                                        onChangeDate={ (date) => {
                                            date && getBookingList(date)
                                            date && setCurrentDate(date)
                                        } }
                                    />
                                </div>

                                {/*<div className="booking__type">*/ }
                                {/*    <FilterSelect*/ }
                                {/*        placeholder={'Все зоны'}*/ }
                                {/*        value={valueZone}*/ }
                                {/*        options={zonesDropdownList}*/ }
                                {/*        onChange={ onFilterChange }*/ }
                                {/*    />*/ }
                                {/*</div>*/ }
                            </div>

                            <div className="booking__blocks-btn__new">
                                {isCreateByRole && (
                                    <Button
                                        className={ 'btn btn-primary' }
                                        onClick={ () => openDialog(true) }
                                        text={ 'Забронировать' }
                                        leftIcon={ (<><img src={ '/img/icon/form/plus-border.svg' }/></>) }
                                    />
                                )}
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row h-100">
                    <div className="col-12 booking-page">
                        <div className="booking__blocks-content">
                            <CalendarBlock
                                selectDate={currentDate}
                                dateWork={ dateWork }
                                list={ bookingList }
                                onClickPropsAdd={ (item, isEdit) => {
                                    isUpdateByRole && openDialog(true, item, isEdit)
                                }}
								updateApi={() => {
									getBookingList(currentDate)
								}}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <DialogContainer
                isOpen={ isShowDialog }
                closeModal={ () => {
                    openDialog(false);
                    setEditBookingData(null);
                } }
                label={ 'Забронировать' }
                classNameModal={'booking__modal'}
            >
                <DialogCalendarBooking
                    userDataCenter={userData?.staffCenter}
                    editData={ editBookingData }
                    resetData={ () => setEditBookingData(null) }
                    zonesList={ zonesDropdownList }
                    closeModal={ () => {
                        openDialog(false);

                        init();
                    } }
                />
            </DialogContainer>
        </>
    );
}

export default BookingPage;
