import React, { FC } from 'react';
import Button from "../form/Button";
import { $startSessionBooking } from "../../api/requests/booking";
import DateOperations from "../../mixins/date-operation";

interface BookingPopupProps {
    item: any;
	updateApi: () => void;
}

const BookingPopup: FC<BookingPopupProps> = (
    {
        item,
		updateApi
    }
) => {
	const dateOperation = new DateOperations();

	if (!item) return null;

	const timeFrom = dateOperation.getTimestampByISODateTime(item?.from_date);
	const timeTo = dateOperation.getTimestampByISODateTime(item?.to_date);
	const currentTime = dateOperation.getTimestampByISODateTime(dateOperation.getISODateTimeByObj(new Date()))

	function onClickStart() {
		$startSessionBooking(item.id).then(res => {
			if (!res.id) return;

			updateApi();
		})
	}

    return (
        <div className="booking__popup">
            <div className="booking__popup__header">
                <div className="title">
                    <span></span>

                    <p>{ item.title }</p>
                </div>
            </div>

            <div className="booking__popup__content">
                <div className="booking__popup__content-block">
                    <p className={'title'}>Начало</p>
                    <p>{ item.dateStart }</p>
                </div>

                <div className="booking__popup__content-block">
                    <p className={'title'}>Конец</p>
                    <p>{ item.dateEnd }</p>
                </div>
            </div>

            <div className="line"></div>

            <div className="booking__popup__content">
                {item.content && item.content.map((i: any, idx: number) => {
                    if (!i.text) return null;

                    return (
                        <div
                            className="booking__popup__content-block"
                            key={`booking-popup-content-${idx}`}
                        >
                            <p className={'title'}>{ i.title }</p>
                            <p>{ i.text }</p>
                        </div>
                    )
                })}
            </div>

			{((currentTime > timeFrom && currentTime < timeTo) && item?.status === 0) && (
				<Button
					text={ 'Запусть сессию' }
					onClick={ onClickStart }
					className={ 'btn btn-primary w-100 justify-content-center' }
				/>
			)}
        </div>
    );
};

export default BookingPopup;
