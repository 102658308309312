import React, { FC, useEffect, useState } from "react";
import Button from "../../components/form/Button";
import PopupDialog from "../../components/dialog/PopupDialog";
import PlusBorder from "../../img/icon/PlusBorder";
import DialogContainer from "../../components/dialog/DialogContainer";
import { ListData } from "../../components/calendar";
import Warning from "../../img/icon/Warning";
import ElectroOn from "../../img/icon/ElectroOn";
import ElectroOff from "../../img/icon/ElectroOff";
import {
	$installPositionDevice,
	$updatePositionDevice
} from "../../api/requests/device";
import { DeviceInstallPositionData, DeviceModel, DeviceUpdatePositionData } from "../../api/requests/device/interface";
import RoleUsers from "../../mixins/role";
import Pc from "../../img/icon/Pc";
import { DialogMapDevicesAdd } from "../../components/dialog/DialogMapDevicesAdd";
import Console from "../../img/icon/Console";
import Vr from "../../img/icon/Vr";
import MapCartIcon from "../../img/icon/MapCartIcon";
import ArrowLeft from "../../img/icon/ArrowLeft";
import { useLocation, useNavigate } from "react-router-dom";
import { $getCurrentZone } from "../../api/requests/zone";

const MapDevicesPage: FC = () => {
	const navigate = useNavigate();

	const location = useLocation();
	const params = new URLSearchParams(location.search);

	const type = params.get('type') || 0;
	const zoneId = params.get('id');

	const roleUsers = new RoleUsers();

	const isCreateByRole = roleUsers.getModelRoleByType("mapCenter", "create");
	const isUpdateByRole = roleUsers.getModelRoleByType("mapCenter", "update");
	const isDeleteByRole = roleUsers.getModelRoleByType("mapCenter", "delete");

	const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
	const [eventPopup, setEventPopup] = useState<MouseEvent | null>(null);
	const [selectPositionData, setSelectPositionData] = useState<any>(null);
	const [isEditBlock, setIsEditBlock] = useState<boolean>(false);
	const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);
	const [deviceList, setDeviceList] = useState<DeviceModel[]>([]);
	const [list, setList] = useState<any[]>([]);
	const [draggedItem, setDraggedItem] = useState<any>(null);
	const [dragOverItem, setDragOverItem] = useState<any>(null);

	const [groupList, setGroupList] = useState<any>([]);

	const listTypeIcon = [
		{
			key: 0,
			icon: <Pc/>
		},
		{
			icon: <Console/>,
			key: 1
		},
		{
			icon: <Vr/>,
			key: 2
		},
		{
			icon: <MapCartIcon/>,
			key: 3
		}
	]

	const handleClick = (event: React.MouseEvent) => {
		const target = event.target as HTMLElement;
		if (!target.classList.contains("btn")) {
			setEventPopup(null);
		}
	};

	const renderItems = () => {
		const rows: JSX.Element[] = [];
		for (let x = 0; x < 15; x++) {
			const blocks: JSX.Element[] = [];
			for (let y = 0; y < 30; y++) {
				const select = list.find((item) => (item.x === x) && (item.y === y));// && ((x > 0) || (y > 0)));

				blocks.push(
					<div className="map-center__device" key={`${y}-${x}`} data-x={x} data-y={y}>
						<div
							className={`map-center__block map-center__block-device${
								select?.name && !select?.warning ? " map-center__selected-device" : ""
							}`}
							onDragOver={(e) => onDragOver(e, x, y)}
							onDrop={(e) => onDrop(e, x, y, select)}
						>
							<div
								draggable
								className={"btn btn-icon"}
								style={{
									backgroundColor: select?.color || ''
								}}
								onClick={(event) => {
									if (!isCreateByRole || !isUpdateByRole || !isDeleteByRole) return;
									onClickOpenPopup(event, select, true, x, y);
								}}
								onDragStart={(e) => onDragStart(e, select)}
							>
								<div className='header__text'>
									{(select?.position || select?.position === 0) && (
										<p className={"number"}>{select.position}</p>
									)}
									<span className={"block"}>
										  {select?.name ? (
												<>
											  <span className={"icon"}>
												{select?.warning ? (
													<Warning color={"#FF5858"} size={24}/>
												) : listTypeIcon.find(item => item.key === select.type)?.icon}
											  </span>
													<span className={"text"}>{select.name}</span>
												</>
											) : (
												<span className={"plus"}>
													<PlusBorder size={22} color={"#3582F6"}/>
												</span>
											)}
										</span>
								</div>
							</div>
						</div>
					</div>
				);
			}
			rows.push(<div className="map-center__border">{blocks}</div>);
		}
		return rows;
	};

	useEffect(() => init(), []);

	function init() {
		if (zoneId) {
			$getCurrentZone({type: type ? +type : 0}).then(res => {
				if (!res.result) return;

				const groupList = res.result
					.map((i: any) => {
						return {
							...i,
							label: i.name,
							value: i.id
						}
					})

				setGroupList(groupList)

				const findDevices = [...groupList].find(i => i.id === zoneId)

				const filterByTypeResponse = [...findDevices.devices].filter(item => (zoneId && (item.zoneId === zoneId)) && (type && (item.type === +type)))

				setDeviceList(filterByTypeResponse);
				setList(
					[...filterByTypeResponse].map((i) => ({
						...i,
						turnOn: i.isOnline,
						turnOff: i.isOnline,
					}))
				);
			})
		}
	}

	function onClickOpenPopup(event: any, item: any, is: boolean, x: number, y: number) {
		const itemUpdate = item
			? item
			: {
				id: null,
				name: "",
				position: null,
				warning: false,
				turnOn: false,
				turnOff: false,
				zone: "",
				x,
				y,
			};
		if (!itemUpdate.name.length) {
			setEventPopup(null);
			return openDialog(is, itemUpdate);
		}
		setSelectPositionData(itemUpdate);
		setEventPopup(event);
	}

	function openDialog(is?: boolean, item?: ListData) {
		setIsShowDialog(is || false);
		setSelectPositionData(!is ? null : item);
	}

	function onClickEditBlock() {
		setIsEditBlock(true);
		setIsShowDialog(true);
		setEventPopup(null);
	}

	function onClickDeleteBlock() {
		const requestData = {
			deviceId: selectPositionData.id || "",
			position: 0,
			type: 0,
		};
		$installPositionDevice(requestData).then((res) => {
			if (!res.id) return;
			resetData();
		});
	}

	function onSaveNewDevicePosition(item: DeviceInstallPositionData) {
		$installPositionDevice(item).then((res) => {
			setIsLoadingRequest(false);
			if (!res.id) return;
			resetData();
		});
	}

	function onSaveUpdateDevicePosition(item: DeviceUpdatePositionData) {
		$updatePositionDevice(item).then((res) => {
			setIsLoadingRequest(false);
			if (!res.length) return;

			setDeviceList(res);
			setList(
				[...res].map((i) => ({
					...i,
					turnOn: i.isOnline,
					turnOff: i.isOnline,
				}))
			);
		});
	}

	function resetData() {
		setEventPopup(null);
		setIsShowDialog(false);
		setIsEditBlock(false);
		setDeviceList([]);
		setIsLoadingRequest(false);
		init();
	}

	function onDragStart(event: React.DragEvent, item: any) {
		if (!item) return;
		setDraggedItem(item);
	}

	function onDragOver(event: React.DragEvent, x: number, y: number) {
		event.preventDefault();
		if (draggedItem && dragOverItem !== `${x}-${y}`) {
			setDragOverItem(`${x}-${y}`);
		}
	}

	function onDrop(event: React.DragEvent, x: number, y: number, select: any) {
		event.preventDefault();
		if (!draggedItem) return;
		const updatedList = list.map((device) => {
			if (device.id === draggedItem.id) {
				if (select) {
					const requestData = {
						id: device.id,
						newId: select.id
					}

					onSaveUpdateDevicePosition(requestData)
				} else {
					const requestData = {
						deviceId: device?.id,
						position: +device.position,
						type: device.type,
						x,
						y,
					}

					onSaveNewDevicePosition(requestData)
				}

				return {...device, x, y};
			}
			return device;
		});
		setList(updatedList);
		setDraggedItem(null);
		setDragOverItem(null);
	}

	function onClickBack() {
		navigate(-1);
	}

	return (
		<div className="map-center dashboard" onClick={handleClick}>
			<div className="map-center-main">
				<div className="container-fluid">
					<div className="map-center-header dashboard__header d-flex align-items-center mb-4">
						<Button
							text={<ArrowLeft/>}
							onClick={onClickBack}
							className={'btn btn-icon mr-3'}
						/>

						<div className="dashboard__header__subject">
							<h2 className={"mb-0"}>Карта устройств</h2>
						</div>
					</div>

					<div className="row">
						<div className="col-12">
							<div className="map-center__content">
								<div className="map-center__devices">{renderItems()}</div>
							</div>
						</div>
					</div>

					{eventPopup && (
						<PopupDialog eventProps={eventPopup} onClose={() => {
						}}>
							<div className="map-center__popup__block">
								<div>
									<div className={"map-center__popup__item"}>
										<Button
											text={"Редактировать"}
											onClick={onClickEditBlock}
											className={"btn btn-primary map-center__popup__btn"}
										/>
									</div>
									<div className={"map-center__popup__item"}>
										<Button
											text={"Удалить"}
											onClick={onClickDeleteBlock}
											className={"btn btn-danger map-center__popup__btn"}
										/>
									</div>
								</div>
								<div className="map-center__popup__header">
									{selectPositionData.warning && (
										<>
											<Warning color={"#FF5858"} size={20}/>
											<p>Неполадки</p>
										</>
									)}
									{selectPositionData.turnOn && (
										<>
											<ElectroOn color={"#70D584"} size={20}/>
											<p>Включен</p>
										</>
									)}
									{selectPositionData.turnOff && (
										<>
											<ElectroOff color={"#FF5858"} width={20} height={20}/>
											<p>Выключен</p>
										</>
									)}
								</div>
								<div className="map-center__popup__content">
									{selectPositionData.zone && <p> {selectPositionData.zone},</p>}
									<p>{selectPositionData.name}</p>
								</div>
							</div>
						</PopupDialog>
					)}

					<DialogContainer
						isOpen={isShowDialog}
						closeModal={openDialog}
						label={
							selectPositionData?.position
								? `Редактирование позиции ${selectPositionData?.position || ""}`
								: "Добавить устройство"
						}
						widthProps={346}
					>
						<DialogMapDevicesAdd
							zoneId={zoneId}
							isType={+type || 0}
							deviceList={deviceList}
							groupList={groupList}
							item={selectPositionData}
							isEdit={isEditBlock}
							onSave={onSaveNewDevicePosition}
							isLoadingRequest={isLoadingRequest}
							setIsLoadingRequest={setIsLoadingRequest}
						/>
					</DialogContainer>
				</div>
			</div>
		</div>
	);
};

export default MapDevicesPage;
