import React, { FC, useEffect, useState } from "react";
import FilterSelect from "../FilterSelect";
import Button from "../form/Button";
import DatePickerField from "../form/DatePickerField";
import DatePickerTimeField from "../form/DatePickerTimeField";
import { SearchItem } from "../form/SearchItem";
import { AddedNewField } from "../form/AddedNewField";
import { $getListRateSecond } from "../../api/requests/rate";
import { $bookingCalculate, $bookingCreating, $deleteBooking, $updateBooking } from "../../api/requests/booking";
import { AvailableDeviceData, BookingLink } from "../../api/requests/booking/interface";
import { $getCurrentAvailable } from "../../api/requests/device";
import { $getCurrentZone } from "../../api/requests/zone";
import DateOperations from "../../mixins/date-operation";
import TextArea from "../form/TextArea";

type DateInterface = Date | null;

interface Dialog {
    editData: any
    userDataCenter: any
    resetData: () => void
    closeModal: () => void
    zonesList: any[]
}

export const DialogCalendarBooking: FC<Dialog> = (
    {
        editData,
        userDataCenter,
        resetData,
        zonesList,
        closeModal
    }
) => {
    const dateOperations = new DateOperations();

    const getDateBySeconds = (value: number, defaultValue: string) => {
        return value
            ? `${dateOperations.getTimeFromSeconds(value)}:00`
            : defaultValue
    }

    const minTimeStart = new Date(`2023-10-08T${getDateBySeconds(userDataCenter?.start, '10:00:00')}`)
    const maxTimeEnd = new Date(`2023-10-08T${getDateBySeconds(userDataCenter?.end, '10:00:00')}`)

    const getTimeDateByNext = (current: Date = new Date(), min: number = 30) => {
        const currentDate = current;
        const timeStart = new Date(currentDate);
        timeStart.setMinutes(currentDate.getMinutes() + min);

        return timeStart
    }

    const [ startDate, setStartDate ] = useState<Date>(new Date());

    const [ startTimeDate, setStartTimeDate ] = useState<DateInterface>(minTimeStart);
    const [ endTimeDate, setEndTimeDate ] = useState<DateInterface>(maxTimeEnd);

    const [ valueDevice, setValueDevice ] = useState<any>(null);
    const [ valueUser, setValueUser ] = useState<any>(null);

    const [ isShowDeviceBlock, setIsShowDeviceBlock ] = useState<boolean>(false);
    const [ resetDataLocal, setResetDataLocal ] = useState<boolean>(false);
    const [ isTariffSelect, setIsTariffSelect ] = useState<boolean>(false);

    const defaultZoneList = (list: any[]): any[] => {
        return [ ...list ].filter(i => i.value)
    }

    const [ zones, setZones ] = useState<any[]>([
        {value: 0, label: 'ПК'},
        {value: 1, label: 'Консоль'},
        {value: 2, label: 'VR'},
        {value: 3, label: 'Спортивные зоны'}
    ]);

    const typesTariffs = [
        {value: 0, label: 'Фиксированный'},
        {value: 1, label: 'Пакетный'}
    ];
    const [ valueZone, setValueZone ] = useState<any>(null);
    const [ valueType, setValueType ] = useState<any>(null);

    const [ groupList, setGroupList ] = useState<any[]>([]);
    const [ valueGroupByZone, setValueGroupByZone ] = useState<any>(null);

    const [ availableDeviceList, setAvailableDeviceList ] = useState<any>(null);
    const [ priceBooking, setPriceBooking ] = useState<number>(NaN);

    const [ tariffs, setTariffs ] = useState<any[]>([]);
    const [ valueTariffs, setValueTariffs ] = useState<any>(null);
    const [ valueComment, setValueComment ] = useState<string>('');

    const getDateByTime = (time: string): Date => {
        const [ fromHours, fromMinutes, fromSeconds ] = time.split(':');

        const currentDate = new Date();

        currentDate.setHours(parseInt(fromHours, 10));
        currentDate.setMinutes(parseInt(fromMinutes, 10));
        currentDate.setSeconds(parseInt(fromSeconds, 10));

        return currentDate;
    };

    const getListById = (list: any[]): string[] => {
        if (!list || !list.length) return []

        return list.map(i => i.id)
    }

    const convertToSeconds = (timeInput: Date | null): number => {
        if (!(timeInput instanceof Date)) return 0

        const hours = timeInput.getHours();
        const minutes = timeInput.getMinutes();
        return hours * 3600 + minutes * 60;
    };

    const getRequestObjData = (): BookingLink => {
        return {
            ...getRequestObjDeviceAvailableData(valueGroupByZone.id),
            "isActive": true,
            "deviceIds": getListById(valueDevice),
            "userIds": valueUser ? getListById(valueUser) : null,
            "comment": valueComment || null
        }
    }

    const getRequestObjDeviceAvailableData = (id: string): AvailableDeviceData => {
        return {
            "date": `${ dateOperations.getISODateByObj(startDate) }T00:00:00.000Z`,
            "from_time": convertToSeconds(startTimeDate),
            "to_time": valueTariffs?.id ? null : convertToSeconds(endTimeDate),
            "zoneId": id,
            "rateId": valueTariffs?.id || null
        }
    }

    const isFormValidate = () => {
        return !valueGroupByZone || !valueDevice// || !valueUser
    }

    const getDurationTypeText = (type: number, count: number) => {
        if (type === 1) {
            if (count === 1) return 'час'

            if (count > 5) return 'часов'

            return 'часа'
        }

        if (count === 1) return 'минута'
        if (count < 5) return 'минуты'

        return 'минут'
    }

    useEffect(() => {
        if (valueGroupByZone?.id) init();
    }, [valueGroupByZone])

    useEffect(() => {
        setIsTariffSelect(valueTariffs?.id || false);

        if (valueTariffs?.id) setEndTimeDate(maxTimeEnd);
    }, [valueTariffs])

    useEffect(() => {
        if (editData) {
			const selectBooking = editData.bookings.find((item: any) => item.id === editData.bookingId)
			const selectTypeFind = typesTariffs.find(item => {
				if ((selectBooking.type === 0) && !item.value) {
					return item.value === 0
				} else {
					return item.value === 1
				}
			})

			selectTypeFind && setValueType(selectTypeFind)
            setStartTimeDate(getDateByTime(editData.from + ':00'));
            setEndTimeDate(getDateByTime(editData.to + ':00'));
            setStartDate(new Date(editData.date))
			setValueComment(editData.comment || "")

            const findZone = zones.find(i => i.value === editData.type);

            if (findZone) {
                onChangeZoneItem(findZone);
            }

            editData.rate && setValueTariffs({
                ...editData.rate,
                label: editData.rate?.name,
                value: editData.rate?.id
            })

            setValueUser(editData.users.map((i: any) => ({
                ...i.user
            })))
        }
    }, [ editData ])

    useEffect(() => {
        if (priceBooking) setPriceBooking(NaN);
    }, [
        valueGroupByZone, valueDevice, valueUser,
        startDate, startTimeDate, endTimeDate
    ])

    function init() {
        // const listId = [ ...zonesList ].filter(i => i.id).map(i => i.id);

        $getListRateSecond({zoneIds: [ valueGroupByZone.id ]}).then(response => {
            if (response && !response.length) return;

            const resUpdate = response.map((i: any) => {
                return {
                    ...i,
                    label: `${i.name}`, // ${i.duration}
                    value: i.id,
                    labelSecond: `${i.duration} ${getDurationTypeText(i.durationType, i.duration)}`,
                }
            })

            setTariffs(resUpdate)
        })
    }

    function onChangeZoneItem(item: any) {
        setValueZone(item);

        setValueGroupByZone(null);
        setIsShowDeviceBlock(false);

        $getCurrentZone({type: item.value}).then(res => {
            if (!res.result) return;

            const groupList = res.result.map((i: any) => {
                return {
                    ...i,
                    label: i.name,
                    value: i.id
                }
            })

            setGroupList(groupList);

            if (editData) {
                const findGroup = groupList.find(i => i.id === editData.zoneId);

                if (findGroup) {
                    setValueGroupByZone(findGroup);
                    onChangeGroupItem(findGroup);
                }
            }
        })
    }

    function onChangeGroupItem(item: any) {
        setValueGroupByZone(item);
        setIsShowDeviceBlock(false);
        setValueDevice(null);

        $getCurrentAvailable(getRequestObjDeviceAvailableData(item.id)).then(res => {
            // if (!res || !res.length) return;

            setAvailableDeviceList(res);

            setIsShowDeviceBlock(true);

            if (editData) {
                setValueDevice(editData.devices.map((i: any) => ({
                    ...i.device
                })))

                const findDevice = res.find(i => {
                    const findId = editData.devices.find((_i: any) => _i.deviceId === i.id)

                    return !!findId;
                });

                if (findDevice) {
                    setValueDevice(findDevice);
                }
            }
        })
    }

    function onClickSave() {
        if (isFormValidate()) return;

        if (!!editData) {
            $updateBooking(editData.bookingId, getRequestObjData()).then(i => {
                closeModal();
                resetDataAll();
            })

            return;
        }

        $bookingCreating(getRequestObjData()).then(i => {
            closeModal();
            resetDataAll();
        })
    }

    function onClickCalculate() {
        if (isFormValidate()) return;

        $bookingCalculate(getRequestObjData()).then(i => {
            if (!i) return;

            setPriceBooking(i.amount);
        })
    }

    function onClickRemove() {
        if (!confirm('Вы подтверждаете отмену?')) return;

        $deleteBooking(editData.bookingId).then(i => {
            if (!i) return;

            closeModal();
            resetDataAll();
        })
    }

    function resetDataAll() {
        setStartDate(new Date());
        setStartTimeDate(new Date());
        setEndTimeDate(new Date());
        setValueGroupByZone(null);
        setValueTariffs(null);
        setResetDataLocal(true);
        resetData()
    }

    return (
		<div className={`added__booking__dialog`}>
			<div className="added__booking__dialog-setting">
				<h4>Настройка</h4>

				<div className={`added__booking__dialog-setting-block`}>
					<div className={'added__booking__dialog-setting-zone'}>
						<FilterSelect
							placeholder={'Выберите тип тарифа'}
							value={valueType}
							options={typesTariffs}
							onChange={(item) => setValueType(item)}
						/>
					</div>

					{valueType && (
						<>
							<div className={'added__booking__dialog-setting-date'}>
								<DatePickerField
									isIcon={true}
									startDate={startDate}
									minDate={new Date()}
									onChangeDate={(date) => {
										valueGroupByZone && onChangeGroupItem(valueGroupByZone)
										setStartDate(date)
									}}
								/>

								<div
									className={`added__booking__dialog-setting-date-time ${!!valueType?.value ? 'is-select-tariff' : ''}`}
									style={!!valueType?.value ? {width: 'auto'} : {}}
								>
									<DatePickerTimeField
										startDate={startTimeDate}
										onChangeDate={(time) => {
											valueGroupByZone && onChangeGroupItem(valueGroupByZone)
											setStartTimeDate(time)
										}}
										minTime={minTimeStart}
										maxTime={getTimeDateByNext(endTimeDate || maxTimeEnd, -30)}
									/>

									{!valueType?.value && (
										<DatePickerTimeField
											startDate={endTimeDate}
											onChangeDate={(time) => {
												valueGroupByZone && onChangeGroupItem(valueGroupByZone)
												setEndTimeDate(time)
											}}
											minTime={getTimeDateByNext(startTimeDate || minTimeStart, 30)}
											maxTime={maxTimeEnd}
										/>
									)}
								</div>
							</div>

							<div className={'added__booking__dialog-setting-zone'}>
								<FilterSelect
									placeholder={'Выберете из списка'}
									value={valueZone}
									options={zones}
									onChange={(item) => onChangeZoneItem(item)}
								/>
							</div>

							<div className={'added__booking__dialog-setting-zone'}>
								<FilterSelect
									placeholder={'Выберете зону'}
									value={valueGroupByZone}
									options={groupList}
									onChange={(item) => onChangeGroupItem(item)}
								/>
							</div>
						</>
					)}

					{(isShowDeviceBlock && !!valueType?.value) && (
						<div className={'added__booking__dialog-setting-zone'}>
							<FilterSelect
								placeholder={'Укажите тариф'}
								value={valueTariffs}
								options={tariffs}
								onChange={(item) => setValueTariffs(item)}
								isClearable
							/>
						</div>
					)}

					{isShowDeviceBlock && (
						<div className={'added__booking__dialog-setting-zone'}>
							<AddedNewField
								wrappedComponent={(FilterSelect)}
								zone={availableDeviceList}
								onChangeValue={(item: any) => setValueDevice(item)}
								resetData={resetDataLocal}
								setResetComplete={() => setResetDataLocal(false)}
								valueArray={valueDevice}
							/>
						</div>
					)}
				</div>
			</div>

			<div className="added__booking__dialog-users added__booking__dialog-setting">
				<h4>Пользователь (-и)</h4>

				<AddedNewField
					wrappedComponent={(SearchItem)}
					textBtnAdd={'Добавить пользователя'}
					valueArray={valueUser}
					onChangeValue={(item: any) => setValueUser(item)}
					resetData={resetDataLocal}
					setResetComplete={() => setResetDataLocal(false)}
				/>
			</div>

			<div className="added__booking__dialog-users added__booking__dialog-setting mt-3">
				<h4>Комментарий</h4>

				<TextArea
					placeholder={'Введите комментарий'}
					imgRight={(
						<p>{`${valueComment.trim().length}/50`}</p>
					)}
					value={valueComment}
					onChangeValue={setValueComment}
					rows={4}
					maxLength={50}
				/>
			</div>

			{(priceBooking >= 0) && (
				<div className={'added__booking__dialog-price'}>
					<p>Цена: {priceBooking} ₽</p>
				</div>
			)}

			<div className="added__booking__dialog-save">
				{!!editData && (
					<Button
						text={!!editData ? 'Изменить' : 'Забронировать'}
						onClick={onClickSave}
						className={'btn btn-primary w-100'}
						disabled={isFormValidate()}
					/>
				)}

				{!editData && (
					<>
						{
							(priceBooking >= 0) ? (
								<Button
									text={!!editData ? 'Изменить' : 'Забронировать'}
									onClick={onClickSave}
									className={'btn btn-primary w-100'}
									disabled={isFormValidate()}
								/>
							) : (
								<Button
									text={'Калькуляция'}
									onClick={onClickCalculate}
									className={'btn btn-primary w-100'}
									disabled={isFormValidate()}
								/>
							)
						}
					</>
				)}
			</div>

			{!!editData && (
				<div className={'added__booking__dialog-cancel'}>
					<Button
						text={'Отменить бронирование'}
						onClick={onClickRemove}
						className={'btn btn-danger w-100 text-nowrap'}
					/>
				</div>
			)}

			{!editData && (
				<div className={'added__booking__dialog-cancel'}>
					<Button
						text={'Отмена'}
						onClick={() => {
							closeModal();
							resetDataAll();
						}}
						className={'btn btn-danger w-100'}
					/>
				</div>
			)}
		</div>
	)
}
